import React, { useEffect, useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Pagination from '@mui/material/Pagination';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Typography, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from 'react-bootstrap';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%', // Adjust width as needed
  height: '70%',
  overflow: 'hidden', // Hide overflow to ensure image fits within modal
  bgcolor: 'rgb(0,0,0,0.5)',
  borderRadius: 'px',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center items horizontally
  justifyContent: 'center', // Center items vertically
  position: 'relative',
};

const imageStyle = {
  maxWidth: '100%', // Ensure image scales to fit the modal width
  maxHeight: '100%', // Ensure image scales to fit the modal height
  width: 'auto',
  height: 'auto',
};

const ViewPhotosPage = ({ cameraID, setLoading, loading, state, setState }) => {

  const ITEMS_PER_PAGE = 12; // Number of items to display per page
  const [photoThumbnail, setPhotoThumbnail] = useState([]);
  const [filteredPhotoThumbnail, setFilteredPhotoThumbnail] = useState([]);
  const [photo, setPhoto] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current photo index
  const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor
  const openMenu = Boolean(anchorEl);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true)  
    fetch('/photos/view_photos/' + cameraID)
      .then(res => res.json())
      .then(data => {
        setPhotoThumbnail(data);
        setFilteredPhotoThumbnail(data);
      })
      .catch(err => console.log(err));
      setLoading(false)
  }, []);

  useEffect(() => {
    setLoading(true)
    if (state && photoThumbnail.length > 0) {
      const startDate = state[0]?.startDate;
      const endDate = state[0]?.endDate;

      const filtered = photoThumbnail?.filter(item => {
        const dateTaken = new Date(item.date_taken);
        return dateTaken >= new Date(startDate) && dateTaken <= new Date(endDate);
      });
      setFilteredPhotoThumbnail(filtered);
    }
    setLoading(false)
  }, [state]);

  const totalPages = Math.ceil(filteredPhotoThumbnail?.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = filteredPhotoThumbnail?.length > 0 ? filteredPhotoThumbnail?.slice(startIndex, endIndex) : [];

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const onThumbnailClick = (index) => {
    setLoading(true)
    setCurrentIndex(index);
    fetch(`/photos/photo/${filteredPhotoThumbnail[index]?.id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setPhoto(data);
        setLoading(false);
        setOpen(true);
      })
      .catch(error => {
        console.error('Error fetching photo:', error);
      });
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setCurrentIndex(prevIndex);
      fetch(`/photos/photo/${filteredPhotoThumbnail[prevIndex].id}`)
        .then(response => response.json())
        .then(data => setPhoto(data))
        .catch(error => console.error('Error fetching photo:', error));
    }
  };

  const handleNextClick = () => {

    if (currentIndex < filteredPhotoThumbnail?.length - 1) {
      const nextIndex = currentIndex + 1;
      setCurrentIndex(nextIndex);
      fetch(`/photos/photo/${filteredPhotoThumbnail[nextIndex].id}`)
        .then(response => response.json())
        .then(data => setPhoto(data))
        .catch(error => console.error('Error fetching photo:', error));
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (index) => {
    fetch(`/photos/photo/${filteredPhotoThumbnail[index]?.id}`, {
      method: 'DELETE', // Use DELETE method for deleting
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // After successful deletion, you can remove the photo from the state
        setLoading({ ...loading, ["circular"]: true });
        const updatedPhotos = filteredPhotoThumbnail.filter((_, i) => i !== index);
        setFilteredPhotoThumbnail(updatedPhotos);
        setLoading({ ...loading, ["circular"]: false });
        setOpen(false);
      })
      .catch(error => {
        console.error('Error deleting photo:', error);
      });
      
    handleMenuClose();
  };

  const handleDownload = () => {
    handleMenuClose();
  };

  const handleViewFullSize = () => {;
    handleMenuClose();
  };

  return (
            <Container>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Fade in={open} timeout={500}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', position: 'relative' }}>
                      <button onClick={handlePrevClick} disabled={currentIndex === 0} style={{ marginRight: '10px' }}>
                        &lt; {/* Left arrow */}
                      </button>
                      <img
                        src={`data:image/jpeg;base64,${photo?.photo_data}`}
                        alt="Photo"
                        style={imageStyle}
                      />
                      <button onClick={handleNextClick} disabled={currentIndex === filteredPhotoThumbnail?.length - 1} style={{ marginLeft: '10px' }}>
                        &gt; {/* Right arrow */}
                      </button>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleMenuClick}
                        style={{
                          position: 'absolute',
                          bottom: '10px',
                          right: '10px',
                          color: 'white',
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                      >
                        <MenuItem style={{color: 'black'}} onClick={() => handleDelete(currentIndex)}>Delete</MenuItem>
                        <MenuItem  style={{color: 'black'}} nClick={handleDownload}>Download</MenuItem>
                        <MenuItem  style={{color: 'black'}} onClick={handleViewFullSize}>View Full Size</MenuItem>
                      </Menu>
                    </div>
                  </Fade>
                </Box>
              </Modal> 
              <Button>View Latest Timelapse</Button> <Button>Take Photo</Button>
              <Grid container spacing={2} style={{ paddingTop: '100px'}}>
                <Grid item xs={12}>
                  <ImageList cols={4} gap={8}>
                    {currentItems?.map((item, index) => (
                      <ImageListItem key={item.id} onClick={() => onThumbnailClick(startIndex + index)}>
                        <img
                          src={`data:image/jpeg;base64,${item.thumbnail_data}`}
                          alt={item.date_taken}
                        />
                        <Typography>{item.date_taken}</Typography>
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Grid>
              </Grid>
            </Container>
  )
}

export default ViewPhotosPage;
