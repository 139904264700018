import {createAuthProvider} from 'react-token-auth'

export const [useAuth, authFetch, login, logout] = createAuthProvider({
    accessTokenKey: 'access_token',
    onUpdateToken: (token) => fetch('/auth/refresh', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.refresh_token}`  // Send refresh token properly
        },
    })
    .then(r => r.json())
    .then(data => data.access_token ? data : token)  // Return new access token
});
