import React, { useState, useEffect } from "react";
import '../styles/main.css';
import { Container } from "react-bootstrap";

const SegmentedProgressBarWithTip = () => {
    const [count, setCount] = useState(0)
    useEffect(() => {
      const interval = setInterval(() => {
        setCount(count + 1)
      }, 250)
      return () => clearInterval(interval)
    });
    return (
    <Container>
      <div className="flex-container">
          <h1 className="box-progress" data-text="loading...">
            loading...
          </h1>
          <span className="box-text">{count}%</span>
        </div>
    </Container>
    )
  }
export default SegmentedProgressBarWithTip;
