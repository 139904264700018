import React, { useState } from 'react';
import { useAuth, logout } from '../auth';
import { Box, Button, BottomNavigation, BottomNavigationAction } from '@mui/material';
import evvpLogo from '../styles/EVVAP-WHITE.png';
import archiveButton from '../styles/archiveButton.png';
import settingsButton from '../styles/settings-button.png';
import logOutButton from '../styles/logout-button.png';
import calendarButton from '../styles/calendarButton.png';
import backButton from '../styles/back-button.png';
import { Card, CardMedia, CardContent } from '@mui/material';
import { DateRange } from 'react-date-range';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArchiveIcon from '@mui/icons-material/Archive';
import SettingsIcon from '@mui/icons-material/Settings';
import Fade from '@mui/material/Fade';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; 
import Modal from '@mui/material/Modal';
import '../styles/main.css';

const videoStyle = {
  maxWidth: '100%', // Ensure image scales to fit the modal width
  maxHeight: '100%', // Ensure image scales to fit the modal height
  width: '100%',
  height: '100%',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%', // Adjust width as needed
  height: '70%',
  overflow: 'hidden', // Hide overflow to ensure image fits within modal
  borderRadius: '8px',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center items horizontally
  justifyContent: 'center', // Center items vertically
};



const NavBar = ({ setShowPhotos, showSettings, showVideos, setShowVideos, setShowSettings, setShowCalendar, showCalendar, showPhotos, loading, albumID, setLoading}) => {
  const [logged] = useAuth();
  const [mobileNavValue, setMobileNavValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [videoURL, setVideoURL] = useState(''); 
  const [disabled, setDisabled] = useState(true);
  const handleClose = () => setOpen(false);
  const handleVideoClose = () => setOpenVideo(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  const handleDateChange = (item) => {
    // Update the state with the new selection
    setState([item.selection]);
  
    // Check if both startDate and endDate are set, then enable the button
    if (item.selection?.startDate && item.selection?.endDate) {
      setDisabled(false);
    }
  };
  const generate_timelapse = async () =>  {
    // Prepare data to send in the request
    setOpen(false);
    const formData = new FormData();
    formData.append('begin_date', state[0]?.startDate);  // Replace with actual begin date
    formData.append('end_date', state[0]?.endDate);    // Replace with actual end date
    try {
      setLoading(true)
      const response = await fetch(`/photos/generate_timelapse/` + albumID, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok){
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setLoading(false)
      setVideoURL('photos/' + result.generated_video_path)
      setOpenVideo(true);

    } catch (error) {
      console.error('Error generating timelapse:', error);
    }
  };

  const handleMobileNavChange = (event, newValue) => {
    setMobileNavValue(newValue);
    if (newValue === 0) showCalendar ? setOpen(true) : setShowVideos(true);  // Archive
    else if (showCalendar || showSettings || showVideos) {
      setShowPhotos(false);
      setShowSettings(false);
      setShowCalendar(false);
      setShowVideos(false);
    } else {
      setShowSettings(true);
    }// Settings
    if (newValue === 2) logout(); // Log Out
  };

  const handleButtonClick = (action) => {
    if (action === 'back') { setShowPhotos(false); setShowSettings(false); setShowVideos(false) };
    if (action === 'archive') setShowVideos(true);
    if (action === 'settings') setShowSettings(true);
    if (action === 'logout') logout();
  };

  return (
    <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Fade in={open} timeout={500}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50%' }}>

              { <>
              <DateRange className='dateRange' style={{ backgroundColor: "rgb(0,0,0,1)", width:'100%' }}
                moveRangeOnFirstSelection={false}
                onChange={handleDateChange}
                showMonthAndYearPickers={false}
                ranges={state}
                showDateDisplay={false}
                > 
              </DateRange> 
              </>    
              }
              </div>
            </Fade>
             <div>
              <Button style={{ borderRadius: 0, color: 'white', backgroundColor: '#ACABAA', marginTop: '30px', width:'154px', height:'34px', backgroundColor: 'rgba(172, 171, 170, .6)'}} onClick={() => { generate_timelapse() }} >GENERATE</Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={openVideo}
          onClose={handleVideoClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Fade in={openVideo} timeout={500}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <Card
              style={videoStyle}
              >
              <CardMedia
                component="video"
                controls
                src={videoURL}
                alt="Video description"
              />
              <CardContent>
              </CardContent>
            </Card>
              </div>
            </Fade>
          </Box>
        </Modal>
      {logged && (
        <>
          {/* Desktop View: Left Sidebar */}
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' }, // Hide on small screens
              position: 'fixed',
              top: 0,
              left: 0,
              width: '20%',
              marginTop: '20px',
              marginLeft: '20px',
              height: '75%',
              borderRadius: '16px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px',
              zIndex: 2, // Ensure proper stacking
            }}
          >
            <Box
              component="img"
              sx={{
                height: 'auto',
                width: '80%',
                marginBottom: '20px',
              }}
              src={evvpLogo}
              alt="Logo"
            />
            {( (!showPhotos && !showSettings  && !showVideos) &&  <> 
            <Button
              sx={{ width: '80%', 
                height: '29px',
                marginTop: '30px', 
                backgroundColor: 'rgba(174, 162, 161, 1)',
                borderRadius: 0,
                color: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 4px' }}
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('archive')}
              endIcon={ <ArchiveIcon style={{width: '38px', height: '29px', backgroundColor: 'rgba(75, 70, 71, 1)' }} />}
            >
              <span style={{ flexGrow: 1, textAlign: 'center', color:'rgba(35, 21, 21, 1)' }}>Archive</span>
            </Button>
            <Button
              sx={{ width: '80%', 
                height: '29px',
                marginTop: '30px', 
                marginBottom: '20px', 
                backgroundColor: 'rgba(174, 162, 161, 1)',
                borderRadius: 0,
                color: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 4px' }}
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick('settings')}
              endIcon={<SettingsIcon style={{width: '38px', height: '29px', backgroundColor: 'rgba(75, 70, 71, 1)' }} />}

            >
              <span style={{ flexGrow: 1, textAlign: 'center', color:'rgba(35, 21, 21, 1)' }}>SETTINGS</span>
            </Button>
            <Button
              sx={{ width: '30%', marginTop: '100px', backgroundColor: 'red',
                color: 'white'}}
              variant="contained"
              color="secondary"
              onClick={() => handleButtonClick('logout')}
            >
              LogOut
            </Button> </>)}
            {(showPhotos) && ( <>
              <Button
                endIcon={<ArrowDropDownIcon style={{width: '38px', height: '29px', backgroundColor: 'rgba(75, 70, 71, 1)' }} />}
                sx={{ 
                  width: '80%', 
                  height: '29px',
                  marginTop: '30px', 
                  marginBottom: '20px', 
                  backgroundColor: 'rgba(174, 162, 161, 1)',
                  borderRadius: 0,
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 4px' // Ensures proper spacing
                }}
                variant="contained"
                color="secondary"
                onClick={() => handleButtonClick('back')}
              >
                <span style={{ flexGrow: 1, textAlign: 'center' }}>Filter</span>
              </Button>
              <DateRange className='dateRange' style={{ width:'100%' }}
                moveRangeOnFirstSelection={false}
                onChange={handleDateChange}
                showMonthAndYearPickers={false}
                ranges={state}
                showDateDisplay={false}
                > 
              </DateRange>
              <Button
              sx={{ borderRadius: 0, width: '50%', marginTop: '30px', backgroundColor: 'red',
                color: 'white'}}
              variant="contained"
              color="secondary"
              onClick={() => generate_timelapse()}
            >
              Generate
            </Button> 
              </>)}
            {(showPhotos || showSettings || showVideos) &&( <Button
              sx={{ width: '30%', backgroundColor: 'rgba(255, 255, 255, 1)', marginTop: '40px',
                color: 'rgba(35, 21, 21, 1)'}}
              variant="contained"
              color="secondary"
              onClick={() => handleButtonClick('back')}
            >
              Back
            </Button>)}
          </Box>

          {/* Mobile View: Bottom Navigation */}
          <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center' }}>
          <BottomNavigation
            sx={{
              display: { xs: 'flex', sm: 'none' }, // Show only on small screens
              position: 'relative',
              marginBottom: '10px',
              borderWidth: '1px',  // Set border thickness here
              borderStyle: 'solid', 
              borderColor: 'rgba(110, 105, 105, 1)',
              borderRadius: '16px',
              backgroundColor: 'rgba(46, 43, 43, 1)',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 2, // Ensure proper stacking
              width: '90%', // Optional: Adjust width as needed
            }}
            value={mobileNavValue}
            onChange={handleMobileNavChange}
          >
            <BottomNavigationAction style={{ borderRight:'1px', borderStyle:'solid', borderColor:'rgba(102, 102, 102, 1)'}} icon={ showPhotos ? <img src={calendarButton}/> : <img src={archiveButton}/> } />
            <BottomNavigationAction style={{ borderRight:'1px', borderStyle:'solid', borderColor:'rgba(102, 102, 102, 1)'}} icon={(showPhotos || showSettings || showVideos) ? <img src={backButton}/> : <img src={settingsButton}/>} />
            <BottomNavigationAction icon={<img src={logOutButton}/>} />
          </BottomNavigation>
        </div>

        </>
      )}
    </>
  );
};

export default NavBar;
