import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Fade from '@mui/material/Fade';

const theme = createTheme({
  height: '.5rem',
  '@media (min-width:600px)': {
    height: '.5rem',
  }
});

theme.typography.h3 = {
fontSize: '11px',
'@media (min-width:600px)': {
  fontSize: '11px',

},
[theme.breakpoints.up('md')]: {
  fontSize: '1.5rem',
},
};

const Camera=({onClick, name, status, setShow, photoCover, loading, setLoading})=>{

  const [color, setColor] = useState();

  const onClickSettings = () => {
    setShow(true);
  }

    return (
            <Box sx={{ margin: "5px" }}>
            <Card sx={{ borderRadius: "0", width: "100%", backgroundColor: "rgba(0,0,0,0)" }}>
              <CardActionArea>
                <CardMedia
                  onClick={onClick}
                  component="img"
                  src={`data:image/jpeg;base64,${photoCover?.thumbnail_data}`}
                  alt="camera"
                  sx={{
                    minWidth: "190px",
                    minHeight: "179px",
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
                <ThemeProvider theme={theme}>
                  <CardActions
                    sx={{
                      backgroundImage: "linear-gradient(to right, rgba(57, 7, 7, 0.7), rgba(182, 20, 26, 0.7));",
                      justifyContent: "space-between",
                      minHeight: "29px",
                    }}
                  >
                    <Typography variant="h3" color="common.white" sx={{ textTransform: "uppercase" }}>
                      {name}
                    </Typography>
                    <CircleIcon sx={{ stroke: "grey", strokeWidth: 2 }} style={{ color: status ? "green" : "black" }} />
                  </CardActions>
                </ThemeProvider>
              </CardActionArea>
            </Card>
          </Box>
    )
}


export default Camera;
